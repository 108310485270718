import { render, staticRenderFns } from "./HasilRekapPembinaan.vue?vue&type=template&id=4c63eb0a&scoped=true&"
import script from "./HasilRekapPembinaan.vue?vue&type=script&lang=js&"
export * from "./HasilRekapPembinaan.vue?vue&type=script&lang=js&"
import style0 from "./HasilRekapPembinaan.vue?vue&type=style&index=0&id=4c63eb0a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c63eb0a",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QSelect,QCardActions,QBtn,QTabs,QRouteTab,QTab,QScrollArea,QList,QItem});
