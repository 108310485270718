<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-gutter-md">
        <q-select
          outlined
          v-model="selKelas"
          label="Kelas"
          :options="kelas"
          @input="getSiswaList"
          v-if="!frominput"
        />
        <q-select
          :disable="frominput || selKelas == null"
          outlined
          v-model="selSiswa"
          label="Siswa"
          :options="siswa"
          @input="getPembinaanDetail()"
        />
        <p class="row justify-between">
          <a>Kelas</a>
          <a>{{ selSiswa.kelas }}</a>
        </p>
        <p class="row justify-between">
          <a>Wali Kelas</a>
          <a>{{ selSiswa.walas }}</a>
        </p>
        <p class="row justify-between">
          <a>Ortu</a>
          <a>{{ nullFilter(selSiswa.ortu) }}</a>
        </p>
        <p class="row justify-between">
          <a>Kontak</a>
          <a>{{ nullFilter(selSiswa.kontak) }}</a>
        </p>
        <p class="row justify-between">
          <a>Peringatan</a>
          <a>{{ nullFilter(selSiswa.peringatan) }}</a>
        </p>
      </q-card-section>
      <q-card-actions class="no-border-radius" align="stretch">
        <q-btn
          flat
          :disable="selSiswa == ''"
          class="full-width"
          label="Download Excel"
          color="primary"
          @click="download"
        ></q-btn>
      </q-card-actions>
      <q-tabs
        v-if="frominput"
        v-model="tab"
        no-caps
        indicator-color="orange"
        class="bg-primary text-white"
      >
        <q-route-tab
          :to="'/penilaianinputpembinaan/' + this.selSiswa.value"
          name="input"
          label="INPUT"
        />
        <q-tab name="riwayat" label="RIWAYAT" />
      </q-tabs>
      <q-scroll-area style="height:calc(100vh - 385px);">
        <q-list bordered separator class="bg-indigo-1">
          <q-item v-if="selSiswa == ''">
            <p class="text-center text-overline" style="width:100%">
              Silahkan Pilih Siswa Dahulu
            </p>
          </q-item>
          <q-item v-else-if="tanggal.length == 0">
            <p class="text-center text-overline" style="width:100%">
              Tidak Ada Riwayat Pembinaan
            </p>
          </q-item>
          <q-item class="column" v-for="(val, i) in tanggal" :key="i">
            <p class="row justify-between q-mb-sm">
              <strong>Tanggal</strong>
              <a>{{ val.tanggal }}</a>
            </p>
            <p class="row justify-between q-mb-sm">
              <strong>Kasus:</strong>
              <a>{{ val.kasus }}</a>
            </p>
            <p class="row justify-between q-mb-sm">
              <strong>Penyelesaian:</strong>
              <a>{{ val.penyelesaian }}</a>
            </p>
            <p class="row justify-between q-mb-sm">
              <strong>Bukti:</strong>
              <a v-if="val.nama_file == '' || val.nama_file == null">-</a>
              <q-btn
                v-else
                flat
                dense
                @click="showDialog(val)"
                color="primary"
                label="show"
                size="sm"
              ></q-btn>
            </p>
          </q-item>
        </q-list>
      </q-scroll-area>
    </q-card>
  </div>
</template>

<script>
import DialogShowGambar from "@/components/DialogShowGambar";
import moment from "moment";
import XLSX from "xlsx";
export default {
  data() {
    return {
      tab: "riwayat",
      kelas: [],
      selKelas: null,
      pembinaan: {
        tanggal: "",
        penjelasan: "",
        penyelesaian: "",
      },
      selSiswa: "",
      siswa: [
        { label: "Siswa 1", value: 1 },
        { label: "Siswa 2", value: 2 },
        { label: "Siswa 3", value: 3 },
      ],
      tanggal: [],
      searchTerm: "",
    };
  },
  computed: {
    frominput() {
      if (this.$route.params.id < 0) return false;
      else return true;
    },
  },
  async mounted() {
    if (this.$route.params.id < 0) {
      await this.getKelas();
      // await this.getSiswaList();
    } else {
      await this.getSiswaDetail();
      await this.getPembinaanDetail();
    }
  },
  methods: {
    async download() {
      let temp = this.tanggal.slice(0);
      let sorted = [];
      for (let item of temp) {
        let carier = {
          nama: this.selSiswa.label,
          tanggal: moment(item.tanggal).format("DD MMMM YYYY"),
          kasus: item.kasus,
          peyelesaian: item.penyelesaian,
        };
        sorted.push(carier);
      }

      var ws = XLSX.utils.json_to_sheet(sorted);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SISWA");

      var now = moment().format("DD MMM YYYY");
      XLSX.writeFile(wb, `data pembinaan ${this.selSiswa.value} ${now}.xlsx`);
    },
    nullFilter(val) {
      if (val && val.length > 0) return val;
      else return "-";
    },
    async getKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/pembinaan/getkelasAll/${localStorage.getItem("jenjang")}`
      );
      this.kelas = resp.data;
      this.$q.loading.hide();
    },
    showDialog(val) {
      this.$q
        .dialog({
          component: DialogShowGambar,
          parent: this,
          nama_file: val.nama_file,
          id_guru: val.id_guru,
          asal: "pembinaan",
        })
        .onOk((resp) => {});
    },
    async getSiswaList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/hasil/rekap_pembinaan/getlist/${this.selKelas.value}`
      );
      this.siswa = resp.data;
      this.$q.loading.hide();
    },
    async getSiswaDetail() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get("/hasil/rekap_pembinaan/getdetail/" + this.$route.params.id)
        .then((resp) => {
          this.selSiswa = resp.data;
        });
      this.$q.loading.hide();
    },
    async getPembinaanDetail() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get("/hasil/rekap_pembinaan/getpembinaan/" + this.selSiswa.value)
        .then((resp) => {
          this.tanggal = resp.data;
        });
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
